<template>
  <el-row class="signin">
    <el-card shadow="never">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-col :span="24">
          <el-form-item>
            <h2>ようこそ！</h2>
            <h5>User IDでサインインしてください。</h5>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" prop="user_name">
            <el-input
              type="text"
              v-model="ruleForm.user_name"
              autocomplete="off"
              placeholder="User ID"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="" prop="pass_word">
            <el-input
              type="password"
              v-model="ruleForm.pass_word"
              autocomplete="off"
              placeholder="Password"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >サインイン</el-button
            >
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item>
            <el-link href="/#/resetpassword" :underline="false" type="primary"
              >User ID/Passwordを忘れですか?</el-link
            >
            <el-divider direction="vertical"></el-divider>
            <el-link href="/#/signup" :underline="false" type="primary"
              >登録</el-link
            >
          </el-form-item>
        </el-col>
      </el-form>
    </el-card>
  </el-row>
</template>
    <!--    <el-row :gutter="0">-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">A</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">B</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">C</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">D</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">E</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">F</el-col>-->
    <!--     <el-col :xs="12" :sm="8" :md="6" :lg="4">G</el-col>-->
    <!--    </el-row>-->
<script>
import { Signin } from "api/user";
import { Message } from "element-ui";

export default {
  name: "SignIn",
  data() {
    let validateUser = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("User IDを入力してください。"));
      } else {
        // if (this.ruleForm.login_user !== '') {
        //   this.$refs.ruleForm.validateField('login_user')
        // }
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Passwordを入力してください。"));
        // } else if (value !== this.ruleForm.pass_word) {
        //   callback(new Error('两次输入密码不一致!'))
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        user_name: "",
        pass_word: ""
      },
      rules: {
        user_name: [{ validator: validateUser, trigger: "blur" }],
        pass_word: [{ validator: validatePass, trigger: "blur" }]
      }
    };
  },
  methods: {
    Signin() {
      let values = {};
      values["user_name"] = this.ruleForm.user_name;
      values["pass_word"] = this.ruleForm.pass_word;
      Signin(values)
        .then(res => {
          if (res.data.status === 1) {
            this.$store.commit("setToken", {
              token: res.data.token,
              full_name: res.data.full_name,
              user_name: this.ruleForm.user_name
            });
            this.$router.push("ProjectList");
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.Signin();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.el-button {
  width: 100%;
}

.signin {
  margin: 0;
  padding: 5% 20% 0 58%;
  background-image: url("../../assets/img/5626873.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
</style>
